import $ from 'jquery';
import 'jquery.mmenu';
import 'jquery.mmenu/dist/jquery.mmenu.css';

class Mmenu {
    constructor() {
        document.addEventListener(
            "DOMContentLoaded", () => {
                this.initMmenu()
            }
        );

        // $.ready(() => );
    }

    initMmenu() {

        // const template = document.querySelector('#mmenu-template').innerHTML

        $('#wlymmenu').mmenu({
            'extensions': [
                'fx-listitems-fade',
                'border-offset',
                'pagedim-black',
                'theme-black'
            ]
        });


        // Mobile Menu Toggler
        $('.menuToggler').click(function () {
            $(this).siblings('.main-navigation').slideToggle();
            $(this).children('svg').fadeToggle();
        });

    }
}

export default new Mmenu();
