let initialized = false;
const callbacks = [];

document.addEventListener('readystatechange', (value) => {
    if (document.readyState !== "interactive" || initialized) {
        return;
    }

    callbacks.forEach(callback => callback());

    initialized = true;
});

export const onReady = (callback) => {
    if (typeof callback !== "function") {
        throw Error('Callback must be function.');
    }

    callbacks.push(callback);
}