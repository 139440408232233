import $ from 'jquery';
import 'lazysizes';

$(() => {
    lazySizes.init();



    // Make fixed Navigation
    $(window).bind('scroll', function () {
        if ($(window).scrollTop() > 300) {
            $('body').addClass('fixed-header');
        } else {
            $('body').removeClass('fixed-header');
        }
    });

/*

    // Initialize Lazy Load
    // Lazy Loading Images
    $('.lazy').lazy({
        effect: "fadeIn",
        effectTime: 500,
        threshold: 300
    });*/


    // Is in scroll View - Count Numbers

    $.fn.isInViewport = function() {
        var elementTop = $(this).offset().top;
        var elementBottom = elementTop + $(this).outerHeight();

        var viewportTop = $(window).scrollTop();
        var viewportBottom = viewportTop + $(window).height();

        return elementBottom > viewportTop && elementTop < viewportBottom;
    };

    var hasLooped = false;

    $(window).on('resize scroll', function() {
        if ( $( ".count" ).length ) {
            if ($('.count').isInViewport()) {
                if (!hasLooped) {
                    $('.count').each(function () {
                        // Count number
                        $(this).prop('Counter', 0).animate({
                            Counter: $(this).text()
                        }, {
                            duration: 3000,
                            easing: 'swing',
                            step: function (now) {
                                $(this).text(Math.ceil(now));
                            }
                        });
                    });
                    hasLooped = true;
                }
            }
        }

    });

    // Scroll to
    $(document).on('click', 'a[href^="\\#"]', function (event) {
        if ($($.attr(this, 'href')).length && $.attr(this, 'href') !== '#wlymmenu') {
            event.preventDefault();
            $('html, body').animate({
                scrollTop: $($.attr(this, 'href')).offset().top - $('header').height()
            }, 1000);
        }
    });

    // Akkordeon
    $('.wly-accordeon__title').click(function() {
        $(this).parent().toggleClass('active');
        $(this).parent().siblings('.wly-accordeon__item').removeClass('active');

        $('.wly-accordeon__item').each(function() {
            if ($( this ).hasClass('active')) {
                $(this).children('.wly-accordeon__content').slideDown();
            } else {
                $(this).removeClass('active');
                $(this).children('.wly-accordeon__content').slideUp();
            }
        });
    });

    // Navigation Touch Geräte
    $('ul.navbar-nav > li.parent > a').on('touchend', function(e) {
        if (!$(this).parent().hasClass('hovered')) {
            e.preventDefault();
            $(this).parent().toggleClass('hovered');
            $(this).parent().siblings().removeClass('hovered');
        }
    });

});
