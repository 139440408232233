import 'materialize-css'

document.addEventListener('DOMContentLoaded', function () {
    // Forms inputs
    M.FormSelect.init(document.querySelectorAll('select'))

    // Sidenav
    // M.Sidenav.init(document.querySelectorAll('.sidenav'));
    let options = {
        inDuration: 50,
        outDuration: 25,
        hover: true, // Activate on hover
        belowOrigin: true, // Displays dropdown below the button
        alignment: 'right' // Displays dropdown with edge aligned to the left of button);
    };
    // Dropdown
    M.Dropdown.init(document.querySelectorAll('.dropdown-button'), options);
    M.Dropdown.init(document.querySelectorAll('.dropdown-button-sub'), options);

    // Datepicker
    /*M.Datepicker.init(document.querySelectorAll('.datepicker'), {
        yearRange: 100
    })*/
})
