import './isotope'
import './baguette-box'
import './form'
import './loveform'
import './googlemapsinfobox'
import './masonry'
import './materialize'
import './mmenu'
import './objectfit-fallback'
import './swiper-main'
import './teich'
import {updateActiveItem} from "./navigation.ts";
import {onReady} from "./ready.ts";

onReady(() => {
    updateActiveItem();
})