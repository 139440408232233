let initialized = false;
const callbacks: any[] = [];

document.addEventListener('readystatechange', (value) => {
    if (document.readyState !== 'complete' || initialized) {
        return;
    }

    callbacks.forEach(callback => callback());

    initialized = true;
});

export const onReady = (callback: any) => {
    if (typeof callback !== 'function') {
        throw Error('Callback must be function.');
    }

    callbacks.push(callback);
};