import baguetteBox from 'baguettebox.js';

document.addEventListener('DOMContentLoaded', function () {

    window.solarrechnerGallery = null;
    window.solarrechnerImages = [];

    function base64ToBlobUrl(base64) {


        const byteString = atob(base64.split(',')[1]);
        const mimeString = base64.split(',')[0].split(':')[1].split(';')[0];
        const byteArray = new Uint8Array(byteString.length);
        for (let i = 0; i < byteString.length; i++) {
            byteArray[i] = byteString.charCodeAt(i);
        }
        const blob = new Blob([byteArray], { type: mimeString });
        return URL.createObjectURL(blob);
    }

    if (document.querySelector('[data-role="solarrechner"]') !== null) {

        window.onload = function() {
            const iframe = document.querySelector('[data-role="solarrechner"] iframe');
            iframe.height = iframe.contentWindow.document.body.scrollHeight;
        }

        window.addEventListener('message', (event) => {
            if (event.origin !== "https://test703.solar-toolbox.ch") {
                return;
            }

            if (event.data.height) {
                const iframe = document.querySelector('[data-role="solarrechner"] iframe');
                iframe.style.height = `${event.data.height}px`;
            }

            if (event.data.id && document.getElementById('myenergysolution-id')) {
                document.getElementById('myenergysolution-id').value = event.data.id;
                // set uuid in hidden text field
            }
            if (event.data.text && document.getElementById('wly_address') && event.data.type === 'addressSelected') {
                document.getElementById('wly_address').value = event.data.text;
            }
            if (event.data.text && event.data.type === 'calculationComplete') {
            }


            if (event.data.action === 'updateImageData') {
                console.log('Updating image data:', event.data.imageBase64s);
                window.solarrechnerImages = event.data.imageBase64s;

                document.getElementById('solarrechner-gallery').innerHTML = '';
                for (let i = 0; i < window.solarrechnerImages.length; i++) {
                    const image = window.solarrechnerImages[i];
                    const blobUrl = base64ToBlobUrl(solarrechnerImages[i]);
                    const link = document.createElement('a');
                    link.href = blobUrl;
                    document.getElementById('solarrechner-gallery').appendChild(link);
                }

                window.solarrechnerGallery = baguetteBox.run('.solarrechner-gallery', {
                    noScrollbars: true,
                    titleTag: true,
                    filter: /blob:http[s]?:\/\/[a-zA-Z0-9.-]+\/[a-fA-F0-9-]+/i
                });
            }

            if (event.data.action === 'openLightbox') {
                console.log('Opening lightbox with start index:', event.data.startIndex);


                if (window.solarrechnerGallery.length > 0) {
                    baguetteBox.show(event.data.startIndex, window.solarrechnerGallery[0]);
                }
            }

            if (document.querySelector('[data-role="solarrechner"]') && event.data.type === 'customScrollToResults') {

                let element = document.querySelector('[data-role="solarrechner"]');
                let bodyRect = document.body.getBoundingClientRect();
                let elemRect = element.getBoundingClientRect();
                let header = document.querySelector('header'); // replace 'header' with your header selector
                let headerHeight = header.offsetHeight;
                let offset = elemRect.top - bodyRect.top + event.data.scrollTo - headerHeight;


                window.scrollTo({
                    top: offset,
                    behavior: 'smooth'
                });
            }

            // todo add hidden input field on contact form below solarrechner
        });











    }
});